import Axios from 'axios'

const axios = Axios.create({
    baseURL: '/api/',
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
})

export default axios