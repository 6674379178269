import React from "react";


const style = {
    fill: 'none',
    fillRule: 'nonzero',
    stroke: '#464d5c',
    strokeWidth: '0.5px',
    strokeDasharray: '5, 5'
}

const defaultStyle = {
    fontFamily: 'Helvetica',
    fontSize: '12px', 
    fill: '#6b7280'
}

function Map({colors}) {
    const handleAtollClick = (forValue) => {    
        const foundLink = colors?.find((item) => item.for === forValue);
        const link = foundLink ? (foundLink.link ? foundLink.link : "/") : "/";
        if (link && typeof link === "string") {
            // router.push(link);
        }

    }

    const getFillColor = (forValue) => {
        const foundColor = colors?.find((item) => item.code.toUpperCase() === forValue);
		let color = '#D1D5DB';

		if(foundColor === undefined) {
			return '#D1D5DB';
		}

		if(foundColor?.muizzvoters > foundColor?.ibuvoters) {
			color = '#54C5CA'
		}
		else {
			color = '#F0C74A'
		}

		if(foundColor?.muizzvoters === 0 && foundColor?.ibuvoters === 0) {
			return '#D1D5DB'
		}

		

		return color;

	    // return foundColor ? foundColor.color : "#D1D5DB";
    }

  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 904 146"
	>
		<g>
			<path
				d="M426.685 145.443V77.411M470.372 126.877V77.411M520.531 58.923v37.438l17.508 26.738M239.812 16.774l7.44 60.637"
				style={style}
			/>
			<path
				d="M295.11 66.407h.62v.138h-.62z"
				style={style}
			/>
			<g id="HDh" onClick={handleAtollClick('HDh')} style={{cursor: 'pointer'}}>
				<g>
					<path
						d="m80.485 128.91 5.259 9.759 9.764 3.541 4.057-.894-1.2-7.529s-8.938-9.283-11.792-8.835c-2.854.448-6.088 3.958-6.088 3.958"
						fill={getFillColor('HDh')}
					/>
					<path
						d="M47.446 100.061s8.841 3.058 11.895 3.645c2.705.517 8.42 1.927 10.967.894 2.866-1.164 5.523-7.676 8.147-9.318 2.136-1.336 7.309-1.939 9.729-2.647 1.361-.4 5.225-2.2 5.225-2.2l-5.706-19.526c-.069-.242-8.977 7.041-13.751 10.21-3.4 2.256-12.582 7.974-15.435 5.328L47.859 76.58l-.413 23.481Z"
						style={{fillRule: "nonzero"}}
						fill={getFillColor('HDH')}
					/>
				</g>
				<g transform="translate(52.833 134.56) scale(.73815)">
					<text
						style={defaultStyle}
					>
						HDH
					</text>
				</g>
			</g>
			<g id="R" onClick={handleAtollClick('R')} style={{cursor: 'pointer'}}>
				<path
					d="M139.408 99.722c1.728 1.547 4.943 4.96 6.91 6.189 3.258 2.033 10.7 4.261 14.234 5.776 4.916 2.106 14.145 9.011 19.492 8.835 3.9-.131 10.3-5.62 13.408-7.976 2.068-1.568 6.511-6.191 6.119-8.389-.4-2.236-3.86-12.442-7.323-14.611-3.277-2.052-11.592-1.294-15.435-.894-4.265.445-14.647 3.988-16.674 3.988-2.027 0-15.609 5.139-20.731 7.082"
					fill={getFillColor('R')}
				/>
				<g transform="matrix(.87318 0 0 .87318 169.649 138.899)">
					<text
						style={defaultStyle}
					>
						R
					</text>
				</g>
			</g>
			<g id="B" onClick={handleAtollClick('B')} style={{cursor: 'pointer'}}>
				<g>
					<path
						d="M212.57 100.612c1.548 3.6-.113 12.5 2.441 15.47 1.666 1.941 7.2 3.066 9.759 3.095 3.912.043 11.8-2.184 15.023-4.4 2.068-1.419 6.133-5.468 6.086-7.975-.055-2.864-5.735-6.432-7.289-8.835-2.323-3.6-6.118-12.411-8.147-15.059-2.029-2.648-6.911-2.215-10.555-1.788-3.678.432-13.768 6.643-13.818 11.069-.032 2.659 5.445 5.98 6.5 8.422m39.808 5.742c-1.653-1.225-3.013-6.168-2.029-7.979.824-1.514 4.929-2.831 6.533-2.2 2.572 1.014 6.625 7.325 5.26 9.73-1.207 2.125-7.8 1.9-9.764.446"
						fill={getFillColor('B')}
					/>
				</g>
				<g transform="matrix(.87318 0 0 .87318 224.716 138.899)">
					<text style={defaultStyle}>
						B
					</text>
				</g>
			</g>
			<g id="AA" onClick={handleAtollClick('AA')} style={{cursor: 'pointer'}}>
				<path
					d="M325.949 116.977c0-3.317-2.116-10.715 0-13.27 2.638-3.188 12.253-2.066 16.259-3.094 5.74-1.473 20.73-6.727 22.76-6.635 1.967.088 7.872.309 7.872.309l-.032 34.895c0-.069-10.974.529-13.511-.688-3.689-1.768-21.966-5.792-23.997-6.225-2.031-.433-7.116-3.8-9.351-5.292"
					style={{fillRule: "nonzero"}}
					fill={getFillColor('AA')}
				/>
				<g transform="translate(335.54 140.808) scale(.78628)">
					<text
						style={defaultStyle}
					>
						AA
					</text>
				</g>
			</g>
			<g id="ADH" onClick={handleAtollClick('ADH')} style={{cursor: 'pointer'}}>
				<path
					d="M372.656 94.479c4.229.166 36.21 1.256 37.83 3.9 1.646 2.685 8.122 11.778 6.91 15.952-1.715 5.907-18.289 13.269-20.73 13.269-2.441 0-20.777 1.279-24.01 1.279"
					fill={getFillColor('ADH')}
				/>
				<g transform="translate(382.221 138.893) scale(.74501)">
					<text
						style={defaultStyle}
					>
						ADH
					</text>
				</g>
			</g>
			<g id="F" onClick={handleAtollClick('F')} style={{cursor: 'pointer'}}>
				<path
					d="M436.097 109.002c.627 3.272 3.562 7.94 6.086 8.87 2.911 1.071 9.417-.875 12.2-2.235 2.691-1.311 7.868-5.156 8.941-7.942 1-2.6.062-8.59-1.2-11.069-1.5-2.933-6.486-8.522-9.764-8.835-3.19-.31-8.681 4.39-10.968 6.634a26.31 26.31 0 0 0-5.295 8.388 17.82 17.82 0 0 0 0 6.189"
					fill={getFillColor('F')}
				/>
				<g transform="matrix(.80293 0 0 .80293 447.787 138.279)">
					<text style={defaultStyle}>
						F
					</text>
				</g>
			</g>
			<g id="DH" onClick={handleAtollClick('DH')} style={{cursor: 'pointer'}}>
				<path
					d="M475.118 106.801c.882 2.55 5.125 6.844 7.735 7.524 3.758.986 11.7-.723 15.435-1.788 2.486-.708 9.351-4.4 9.351-4.4s5.787-6.215 6.5-8.87c.6-2.25-.828-7.518-4.058-8.389-3.269-.881-10.65-3.408-14.232-4.434-3.035-.869-10.565-2.655-12.618-.448-2.038 2.197-5.866 8.329-6.875 11.522-.705 2.233-2 7.07-1.238 9.283"
					fill={getFillColor('DH')}
				/>
				<g transform="translate(487.74 141.561) scale(.81973)">
					<text style={defaultStyle}>
						DH
					</text>
				</g>
			</g>
			<g id="TH" onClick={handleAtollClick('TH')} style={{cursor: 'pointer'}}>
				<path
					d="M525.104 79.367c-1.146 2.038-.622 12.004 1.612 15.058 1.864 2.538 9.349 5.279 11.8 4.4 2.451-.879 9.352-.429 15.059-.859 5.673-.429 14.9 4.2 17.876.859 2.487-2.787-1-11.611-3.265-14.576-2.233-2.916-8.114-7.082-10.143-10.623-2.029-3.541-5.727-11.515-6.127-17.258-.4-5.774-2.82-10.624-6.5-10.624-3.645 0-13 8.383-15.437 11.483-2.45 3.122-.531 14.167-2.028 18.6-.364 1.077-2.3 2.549-2.854 3.541"
					fill={getFillColor('TH')}
				/>
				<g transform="translate(552.971 142.193) scale(1.00449)">
					<text style={defaultStyle}>
						TH
					</text>
				</g>
			</g>
			<g id="GDH" onClick={handleAtollClick('GDH')} style={{cursor: 'pointer'}}>
				<path
					d="M745.782 95.456c2.715 3.85 8.182 8.973 10.485 8.973 3.333 0 10.695 1.5 14.026.756 3.331-.744 16.7-1.48 18.049-5.122 1.34-3.612 4.695-10.173 5.363-15.986.673-5.843 4-21.835.655-27.675-3.328-5.812-8.178-14.042-12.688-16.742a3.622 3.622 0 0 0-2.338-.516c-15.04 6.505-33.552 56.312-33.552 56.312"
					style={{fillRule: "nonzero"}}
					fill={getFillColor('GDH')}
				/>
				<g transform="translate(761.994 138.785)">
					<text style={defaultStyle}>
						GDH
					</text>
				</g>
			</g>
			<g id="S" onClick={handleAtollClick('S')} style={{cursor: 'pointer'}}>
				<path
					d="M883.948 92.809c.5-4.185-1.679-14.672 1.993-16.742 1.924-1.085 6.315 2.272 8.046 3.643 3.919 3.106 13.993 18.187 8.32 18.187s-13.939-3.315-18.359-5.088"
					fill={getFillColor('S')}
				/>
				<g transform="translate(891.634 108.65)">
					<text style={defaultStyle}>
						S
					</text>
				</g>
			</g>
			<g id="C" onClick={handleAtollClick('C')} style={{cursor: 'pointer'}}>
				<path
					d="M93.411 90.435s10.6-1.749 13.912-2.441a122.606 122.606 0 0 0 16.2-4.779c3.422-1.4 13.237-6.507 13.237-6.507 1.65-16.764-1.377-27.505-6.807-32.1 0 0-17.228 12.4-21.281 14.611-4.092 2.228-20.969 11.688-20.969 11.688-.413 13.409-2.112 7.461 5.708 19.528"
					fill={getFillColor('C')}
				/>
				<g transform="matrix(.75309 0 0 .75309 94.291 30.014)">
					<text
						style={defaultStyle}
					>
						SH
					</text>
				</g>
			</g>
			<g id="N" onClick={handleAtollClick('N')} style={{cursor: 'pointer'}}>
				<path
					d="M135.729 76.79c.177.894 8.837-3.124 10.177-3.61 2.443-.886 12.606-1.344 13.406-3.541.817-2.239 4.615-12.947 3.679-17.258-1.122-5.162-12.204-17.262-12.204-17.262s-12.751 3.482-15.849 5.744c-1.356.992-5.432 3.956-5.432 3.956l6.223 31.971Z"
					style={{fillRule: "nonzero"}}
					fill={getFillColor('N')}
				/>
				<g transform="matrix(.84244 0 0 .84244 138.337 26.836)">
					<text
						style={defaultStyle}
					>
						N
					</text>
				</g>
			</g>
			<g id="LH" onClick={handleAtollClick('LH')} style={{cursor: 'pointer'}}>
				<path
					d="M180.044 32.917c2.057 3 6.216 9.07 8.938 11.482 2.574 2.281 8.705 8.6 11.793 7.082 3.9-1.915-.609-13.8 2.027-17.258 1.8-2.352 9.262-2.018 10.968-4.436 1.671-2.368.4-9.719-1.617-11.482-2.037-1.778-11.378-9.729-13.407-9.729s-11.38 9.286-13.407 11.934c-2.027 2.648-4.225 9.207-5.295 12.407"
					fill={getFillColor('LH')}
				/>
				<g transform="matrix(.776 0 0 .776 219.998 21.068)">
					<text
						style={defaultStyle}
					>
						LH
					</text>
				</g>
			</g>
			<g id="K" onClick={handleAtollClick('K')} style={{cursor: 'pointer'}}>
				<g>
					<path
						d="M269.876 47.051c.069-.765.208-2.295.275-3.06.134-1.556-.038-4.763.516-6.223 1.566-4.133 6.55-12.365 10.588-14.163 1.3-.578 4.863-1.164 5.673 0 3.105 4.467-5.688 15.3-7.7 20.352-1.091 2.733-6.124 14.612-7.735 8.87-1.617-5.775-1.617-7.275-1.617-5.776m21.933-3.096c2.235 1.22 6.489 4.187 8.939 4.882 4.015 1.141 12.511.579 16.673.894 3.188.245 9.468 2.774 12.618 2.2 2.778-.507 7.839-3.643 9.729-5.741 1.8-1.995 4.469-9.764 4.469-9.764s-4.079-5.443-5.672-7.048c-2.9-2.923-9.764-7.976-12.2-11.07-2.436-3.094-8.535-14.62-10.968-12.411-2.45 2.225-8.518 9.771-11.791 13.3-3.262 3.519-9.243 18.397-11.797 24.758"
						fill={getFillColor('K')}
					/>
					<path
						d="M346.677 40.415s4.418 6.165 6.5 7.529c3.115 2.043 10.509 4.604 14.231 4.435 3.188-.145 10.721-1.877 11.791-4.882.951-2.669-4.065-7.518-6.5-9.283-2.448-1.776-10.169-11.09-12.994-11.963-2.848-.88-12.639-1.762-13.442.446a93.754 93.754 0 0 0-2.027 9.73l2.441 3.988Z"
						fill={getFillColor('K')}
					/>
				</g>
				<g transform="matrix(.70146 0 0 .70146 288.899 16.077)">
					<text
						style={defaultStyle}
					>
						K
					</text>
				</g>
			</g>
			<g id="V" onClick={handleAtollClick('V')} style={{cursor: 'pointer'}}>
				<path
					d="M402.118 51.248c-1.966-2.442-2.87-9.356-2.028-12.376 1.134-4.07 5.916-10.174 9.859-11.689 2-.769 10.915 1.736 9.285-.476-1.63-2.212-2.569-14.053-2.884-15.713-.6-3.169 3.516-6.558 6.716-6.151 4.861.62 9.502.605 9.091 8.547-.414 7.975-2.253 13.891.455 18.85 2.089 3.824 12.564 6.45 13.82 10.623 1.024 3.4-7.616 8.8-9.644 9.247-2.028.447-11.509-1.738-13.536-1.738-2.027 0-9.401 4.951-12.195 4.417-2.36-.45-7.431-1.669-8.938-3.541"
					fill={getFillColor('V')}
				/>
				<g transform="translate(438.535 24.596)">
					<text style={defaultStyle}>
						V
					</text>
				</g>
			</g>
			<g id="M" onClick={handleAtollClick('M')} style={{cursor: 'pointer'}}>
				<path
					d="M456.828 44.851c.621 1.914 1.009 6.722 2.854 7.529 3.514 1.538 10.396-5.741 14.23-5.741 4.708 0 12.778 7.489 17.465 7.048 3.244-.3 10.577-3.812 10.968-7.048.512-4.25-9.619-8.724-11.792-12.411-2.076-3.523-4.466-14.178-7.322-14.611-2.818-.426-11.186 1.995-14.611 3.541-3.636 1.641-10.77 6.228-12.618 9.763-.868 1.664-.579 5.629-.378 7.5a29.934 29.934 0 0 0 1.2 4.434"
					fill={getFillColor('M')}
				/>
				<g transform="translate(470.107 10.875)">
					<text style={defaultStyle}>
						M
					</text>
				</g>
			</g>
			<g id="L" onClick={handleAtollClick('L')} style={{cursor: 'pointer'}}>
				<path
					d="M575.916 29.797c2.42 2.359 7.726 7.535 9.351 9.764 1.608 2.205 4.469 18.117 6.5 19.458 2.031 1.341 8.111 7.078 10.965 6.184 2.854-.894 8.452-1.867 10.175-3.988 2.342-2.882 3.066-10.87 2.854-14.576-.193-3.368-4.091-12.858-4.091-12.858s-6.91-4.847-10.555-4.847c-3.645 0-14.358-1.542-19.615-1.115-5.299.431-5.584.646-5.584 1.978"
					fill={getFillColor('L')}
				/>
				<g transform="translate(591.643 8.676)">
					<text style={defaultStyle}>
						L
					</text>
				</g>
			</g>
			<g id="GA" onClick={handleAtollClick('GA')} style={{cursor: 'pointer'}}>
				<path
					d="M710.818 68.056c1.289 3.615 4.675 13.1 8.01 13.1s10.029-10.924 15.367-4.366c5.353 6.572 11.513 18.635 11.513 18.635s40.4-52.956 35.963-55.766c-3.814-2.417-13.364-3.636-18.047.756-4.668 4.375-15.395 13.8-19.39 12.341-4.016-1.463-10.692-6.531-16.055-4.364-5.329 2.199-13.641 14.264-17.361 19.664"
					fill={getFillColor('GA')}
				/>
				<g transform="translate(723.802 29.093)">
					<text style={defaultStyle}>
						GA
					</text>
				</g>
			</g>
			<g id="GN" onClick={handleAtollClick('GN')} style={{cursor: 'pointer'}}>
				<path
					d="M864.249 41.551c-2.493 1.658-5.47-.929-5.774-5.069-.331-4.499-2.826-8.889.651-8.889 6.394 0 .777 5.382 1.583 6.978.998 1.976 5.176 5.907 3.54 6.98Z"
					fill={getFillColor('GN')}
				/>
				<g transform="translate(855.564 18.198)">
					<text style={defaultStyle}>
						GN
					</text>
				</g>
			</g>
			<path
				d="M87.26 16.773v65.028l40.31 63.642M136.589 145.443v-88.3l-22.756-40.37M295.11 145.443V77.411M373.134 145.443V77.411M388.792 77.411V16.774M450.909 77.411V16.774M190.047 77.411l-31.825-60.637M200.277 77.411l9.269 68.032M712.363 145.443l77.489-128.67"
				style={style}
			/>
			<g id="HA" onClick={handleAtollClick('HA')} style={{cursor: 'pointer'}}>
				<path
					d="M47.55 16.774v128.669"
					style={style}
				/>
				<g>
					<g>
						<path
							d="M.383 107.248c0-2.544-.637-7.713 0-10.177.475-1.838 2.084-5.608 3.679-6.638 1.2-.774 4.322-.082 5.706-.446 1.514-.4 4.123-2.871 5.672-2.648 2.146.31 6.091 3.612 6.5 5.742.466 2.431-2.747 7.058-4.47 8.835-1.77 1.829-6.7 3.662-8.938 4.882-2.038 1.113-6.156 5.623-8.149 4.435-.857-.51 0-2.991 0-3.988"
							fill={getFillColor('HA')} />
						<path
							d="M19.911 71.391a32.614 32.614 0 0 0 3.265 6.189c1.069 1.257 4.012 2.908 5.26 3.988 2.451 2.119 6.243 7.464 8.56 9.729 2.461 2.409 10.555 8.869 10.555 8.869l.413-23.446c.033-1.615-6.9-7.095-9.351-8.422a89.94 89.94 0 0 0-9.764-3.988c-2.436-.874-8.837-.859-11.792-.859 0 0 2 6.011 2.854 7.941"
							style={{fillRule: "nonzero"}}
							fill={getFillColor('HA')}
						/>
					</g>
					<g transform="matrix(.74767 0 0 .74767 14.128 31.01)">
						<text style={defaultStyle}>
							HA
						</text>
					</g>
				</g>
			</g>
		</g>
	</svg>
  );
}

export default Map;
