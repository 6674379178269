const Logo = ({className}) => {
	return (
		<svg className={`${className}`} viewBox="0 0 122.3 58">
		  <g fill="#0F72AF" fillRule="evenodd" clipRule="evenodd">
			<path d="M79 11.5c2.1-3.8 1.7-8.3-2.7-9.6-4.1-1.2-6.5 3-9.2 6.1-4 4.7-18.2 18.4-22.6 21.6-8.6 6.2-17.2 12.9-27.4 16.4-3.8 1.3-6.9.2-6.5-4.1.4-3.6 2.3-7.1 4-10.2 2.8-5 6.2-9.9 9.9-14.2 1.3-1.5 6.5-7.1 8.8-6 .1.1.2.1.3.2.5 2.5-2.1 5.3-3.9 6.7-2.1 1.7-4.6 2.9-6.8 4.5s-4 3.8-3.4 6.7c.4 2.3 2.6 4.1 4.9 4.1 2.7 0 5-1.8 7.1-3.3 2.3-1.7 4.5-3.6 6.5-5.7 3.6-4 7.4-9.9 6.9-15.6C44.4 3.7 39.8.7 34.8.3c-.8 0-1.4-.1-3.3.4C23.2 2.8 18 8.9 13.1 15.5 8.4 21.8 3.8 28.8 1.5 36.3-.2 41.8-.6 49 4 53.2c5.5 5.1 13.9 3.2 20.2.9 7.5-2.8 14.3-7.2 21-11.5 4.7-3.1 31.4-26.8 33.8-31.1zM93 35.8c.9-1.1 1.8-3.3 2-4.5 0-.3 1.4 0 3.4-1.7 2.3-2 5.1-4.2 7-6.6 3.5-4.1 9-10.3 10.3-11.7 3.4-3.9 2.6-8.1-.7-9.4-4.3-1.7-8.7 5.8-12 9.6-2.8 3.2-11.2 10.9-11.2 10.9-1.5-2.9-5.2-5.4-10.6-4.3-3.3.6-5.9 2.7-8.4 4.9-6.1 5.4-8.3 12.8-4.7 20.3 0 0-.9 1.5-6.7 3.9-1.9.8-5.7 2.7-4 6.9 1.8 4.6 6.5 4.6 9.7 2.5 9.8-6.3 8.9-5.9 12.9-9.1 4.5-3.8 9.4-7.2 13-11.7zm-16-1.6c-.7-2.4.7-4.1 2.3-4.8 1.4-.6 2.7-.2 2.7-.2-.2 2.4-2.2 4.7-5 5zM121.8 39.9c1-7.1.6-15.5-6.5-16.2-.5 0-2.2-.1-4.7 1.3-4.8 2.4-15.4 13.5-22.3 21.9-2.2 2.6.5 6.9 3.8 7.8 5.5 1.4 7.9-5.1 15-12.1 1.8-1.8 3.5-4.2 4.4-4.6.3-.1.3.2.4.4.2 4.7-7.3 13.4 0 15.9 7.1 2.2 9.3-10 9.9-14.4z"></path>
		  </g>
		</svg>
	  );

}
export default Logo