
import Logo from '../components/Logo';

const Topbar = () => {

return (
	<header className="">
		<a
			to="/"
			className="flex justify-center flex-col items-center space-y-6 sm:space-y-10 pt-12"
		>
			<Logo className="w-16 sm:w-20" />
			<h2 className="text-2xl sm:text-4xl text-slate-800 font-aammu dir-rtl">
				ރިޔާސީ އިންތިޚާބު 2023
			</h2>
		</a>
	</header>
)

}
export default Topbar