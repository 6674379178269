import { Fragment, useEffect, useState } from "react"
import CandidateCard from '../components/CandidateCard'
import StatsBlock from '../components/StatsBlock'
import VoteBoxCard from '../components/VoteBoxCard'
import axios from '../lib/axios';
import Map from "../components/Map";


// const colors = [
// 	{
// 		for: "HA",
// 		color: "#53C7CB",
// 		link: "/atoll/HA",
// 	},
// 	{
// 		for: "HDH",
// 		color: "#F7C412",
// 		link: "/atoll/HDH",
// 	},
// 	{
// 		for: "SH",
// 		color: "#53C7CB",
// 		link: "/atoll/SH",
// 	},
// 	{
// 		for: "N",
// 		color: "#53C7CB",
// 		link: "/atoll/N",
// 	},
// 	{
// 		for: "R",
// 		color: "#53C7CB",
// 		link: "/atoll/R",
// 	},
// 	{
// 		for: "LH",
// 		color: "#53C7CB",
// 		link: "/atoll/LH",
// 	},
// 	{
// 		for: "B",
// 		color: "#53C7CB",
// 		link: "/atoll/B",
// 	},
// 	{
// 		for: "K",
// 		color: "#53C7CB",
// 		link: "/atoll/K",
// 	},
// 	{
// 		for: "AA",
// 		color: "#F7C412",
// 		link: "/atoll/AA",
// 	},
// 	{
// 		for: "ADH",
// 		color: "#53C7CB",
// 		link: "/atoll/ADH",
// 	},
// 	{
// 		for: "V",
// 		color: "#53C7CB",
// 		link: "/atoll/V",
// 	},
// 	{
// 		for: "F",
// 		color: "#53C7CB",
// 		link: "/atoll/F",
// 	},
// 	{
// 		for: "M",
// 		color: "#53C7CB",
// 		link: "/atoll/M",
// 	},
// 	{
// 		for: "DH",
// 		color: "#53C7CB",
// 		link: "/atoll/DH",
// 	},
// 	{
// 		for: "TH",
// 		color: "#53C7CB",
// 		link: "/atoll/TH",
// 	},
// 	{
// 		for: "L",
// 		color: "#F7C412",
// 		link: "/atoll/L",
// 	},
// 	{
// 		for: "GA",
// 		color: "#53C7CB",
// 		link: "/atoll/GA",
// 	},
// 	{
// 		for: "GDH",
// 		color: "#53C7CB",
// 		link: "/atoll/GDH",
// 	},
// 	{
// 		for: "GN",
// 		color: "#53C7CB",
// 		link: "/atoll/GN",
// 	},
// 	{
// 		for: "S",
// 		color: "#53C7CB",
// 		link: "/atoll/S",
// 	},
// ];


const Home = () => {

    const [atolls,setAtolls] = useState()
    const [candidates , setCandidates] = useState();
    const [resources,setResources] = useState()
    const [ballots,setBallots] = useState({
		current_page: 1,
		data: []
	})
	const [page,setPage] = useState(1);

    const fetchAtolls = async() => {
        const results = await axios.get('/atolls').then(res => res.data);
        setAtolls(results)
    }
    const fetchCandidates = async() => {
        const results = await axios.get('/candidates').then(res => res.data);
        setCandidates(results)
    }
    
    const fetchResources = async() => {
        const results = await axios.get(`/resources`).then(res => res.data);
        setResources(results)
    }
    
	const fetchBallots = async() => {
        const results = await axios.get(`/ballots?page=${page}`).then(res => res.data);
        setBallots(ballots)

		const loadData =  {
			...ballots,
			data: [...ballots.data, ...results.data]
		}

		setBallots(loadData)



    }

    useEffect(()=> {
        fetchAtolls()
        fetchCandidates();
        fetchResources();
		fetchBallots();

    },[])

	useEffect(()=> {
		fetchBallots()
	},[page])
    return (
        <Fragment>
            
            <section className="mt-20 bg-black/5 rounded-lg p-5 mb-20">
                <Map colors={resources?.colors} />
            </section>
            <section className="dir-rtl grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
                {candidates?.map((candidate,index) => (
                    <CandidateCard key={index} candidate={candidate} />
                ))}
            </section>
            <section className="dir-rtl mt-20">
                <h3 className="text-lg md:text-2xl font-aammu text-slate-700">ގުނާ ނިމުނު ފޮށިތަށް</h3>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">
					{resources?.stats?.map((stat,index) => (
                        <StatsBlock key={index} stat={stat} />
					))}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-5">
                    {ballots?.data?.map((box,index) => (
                        <VoteBoxCard  key={index} box={box} />
                    ))}

                </div>
				<div className="items-center place-content-center justify-center flex">
					<button  onClick={() => setPage(prev => prev+1)} className="bg-avasPrimary rounded-lg text-white p-2">Load More</button>
				</div>
            </section>
            <section className="dir-rtl mt-20">
                <h3 className="text-lg md:text-2xl font-aammu text-slate-700">އަތޮޅުތަށް</h3>
                <div className="flex flex-wrap gap-3 mt-5">
                    {atolls?.map((atoll,index) => (
                        <Fragment key={index}>
                        <a href={`/atolls/${atoll.dv_name}`} className="shrink-0 text-lg font-faseyha text-slate-700 hover:text-white bg-black/5 hover:bg-avasPrimary transition-colors duration-150 ease-linear cursor-pointer px-8 py-2 rounded-full inline-block">
                            {atoll?.dv_name}
                        </a>
                        </Fragment>
                    ))}
                </div>
            </section>
        </Fragment>
    )
}

export default Home