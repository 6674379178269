import { useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import axios from '../../lib/axios';
import CandidateCard from '../../components/CandidateCard';
import VoteBoxCard from '../../components/VoteBoxCard';
import StatsBlock from '../../components/StatsBlock';


const Atolls = () => {
    const [resources, setResources] = useState();
    let { slug } = useParams();

    const fetchResources = async() =>{
        const results = await axios.get(`/ballotbox/atoll/${slug}`).then(res => res.data);;
        setResources(results)

    }
    useEffect(() => {
        fetchResources()
    },[])

    return (
        <section className="dir-rtl mt-20">
			<h3
				className="text-2xl md:text-4xl text-center mb-12 font-aammu text-slate-700"
			>
				{resources?.atoll?.dv_name}
			</h3>


			{/* <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">
				<template v-for="stat in stats">
					<StatsBlock />
				</template>
			</div>

			<section
				className="dir-rtl grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 my-20"
			>
					<CandidateCard candidate={resources?.candidate} showProgress={true} />
			</section> */}

			<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-5">
                {resources?.boxes.map((box,index) => (
                    <VoteBoxCard key={index}  box={box} />
                ))}
			</div>
		</section>
    )
}

export default Atolls;