import Logo from '../components/Logo'
const Footer = () => {
    return (
        <footer className="mt-20 p-5">
		<div className="container mx-auto">
			<Logo className="w-12 mx-auto" />
			<span className="text-xs pt-3 pb-10 text-slate-500 block text-center"
				>&copy;2023 Avas.mv. All rights reserved.</span>
		</div>
	    </footer>
    )
}

export default Footer;