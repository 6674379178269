import { useEffect } from 'react';
import ProgressBar from './ProgressBar';


const CandidateListItem = ({candidate,percent}) => {


	
    return (
        <div className={`space-y-3 py-2 bg-white rounded-lg p-3`}>

		<div className="flex items-center">
			<span className="text-lg shrink-0 pl-3 font-medium text-right text-avasPrimary">{ candidate?.candidate_no }</span> 
			<div className="w-12 h-12 shrink-0 overflow-hidden rounded-lg rounded-se-xl">
				<img src={`https://kuri-baalidhy.s3.ap-southeast-1.amazonaws.com/pres2023/${candidate.image}`} />
			</div>
			<div className="pr-2 grow">
				<div className="flex items-center">
					<span className="block grow text-base font-waheed text-slate-800">{candidate?.dv_name}</span>
					<span className="text-xs text-slate-600 font-medium">{percent.toFixed(2)}%</span>
				</div>
				<div className="grow pt-2">
					<ProgressBar percent={percent} />
				</div>
			</div>
		</div>
	</div>
    )
}

export default CandidateListItem;