import { useEffect } from 'react';
import CandidateListItem from './CandidateListItem';

const VoteBoxCard = ({box}) => {

    return (
        <div className="bg-black/5 p-3 rounded-md">
            <div className="text-center mb-3">
                <h2 className="text-xl font-medium text-slate-700">{box?.ballot_no}</h2>
                <span className="block text-sm text-slate-600 mt-1">{box?.dv_name}</span>
                    <span className="inline-flex items-center gap-2 justify-center bg-avasPrimary/20 rounded-full px-4 mt-3 py-1">
                    <span className="text-sm font-faseyha text-slate-600 relative -bottom-[2px]">ވޯޓު ލެވޭ</span>
                    <span className="text-sm text-slate-500 font-medium">{box?.total_voters}</span>
                    <span className="text-sm font-faseyha text-slate-600 relative -bottom-[2px]">ވޯޓު ލި</span>
                    <span className="text-sm text-slate-500 font-medium">{box?.total_voted}</span>
                </span>
            </div>
            <div className="rounded-b-md space-y-2">
                {box?.results.map((result,index) => (
                    <CandidateListItem key={index} percent={result.percent} color={result?.ballotWinner} candidate={result?.candidate} />
                ))} 
            </div>
        </div>
    )
}

export default VoteBoxCard;