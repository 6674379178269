import React from 'react';
import Topbar from "./Topbar";
import Footer from "./Footer"
const Layout =({children}) =>{
    return(
        <>
            <Topbar />
              <main className='container mx-auto mt-20 px-4 sm:px-0'>
                    {children}
               </main>
            <Footer />
        </>
    )
}

export default Layout;