const ProgressBar = ({percent=10,height='h-[5px]'}) => {
    return (
		<div className={`w-full rounded-full bg-black/5 overflow-hidden ${height}`}>
			<div
				className="h-full bg-avasPrimary rounded-full transition-all duration-100 ease-out"
				style={{width: percent+'%'}}
			></div>
		</div>
    )
}

export default ProgressBar;