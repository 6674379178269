import React from "react";
import {
  Routes,
  Route,
} from 'react-router-dom';

import Layout from "../src/components/Layout";
import Home from '../src/pages/home.js'
import Atolls from "./pages/atolls/atoll";

function App() {
  return (
    <section>
      <Layout>
          <Routes>
            <Route path="/"  element={<Home />} />
            <Route path="/atolls/:slug"  element={<Atolls />} />
          </Routes>
      </Layout>
    </section>
  );
}

export default App;