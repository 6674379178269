const StatsBlock = ({stat}) => {

    return (
    <div className="bg-black/5 rounded-md p-3">
		<span className="block text-3xl font-medium text-avasPrimary">{stat.count}</span>
		<span className="block text-base mt-1 font-waheed text-slate-600">{stat.label}</span>
	</div>
    )
}

export default StatsBlock;