import ProgressBar from "./ProgressBar";


const CandidateCard = ({candidate,showProgress=false}) => {

    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="space-y-3">
		<div className="flex items-end">
			<div
				className="shrink-0 w-16 md:w-28 h-16 md:h-28 overflow-hidden rounded-lg rounded-se-3xl"
			>
				<img src={`https://kuri-baalidhy.s3.ap-southeast-1.amazonaws.com/pres2023/${candidate?.image}`} />
			</div>
			<div className="grow pr-2">
				<div className="flex items-center">
					<span className="grow text-2xl md:text-4xl font-semibold text-avasPrimary">{ candidate?.candidate_no }</span>
                    {showProgress && 
					<span
						className="shrink-0 text-sm text-slate-400 font-medium"
						>{ candidate?.percentage}</span>
                    }
				</div>
				<span className="block text-lg md:text-xl font-waheed text-slate-800">{candidate?.dv_name}</span>
				<div className="mt-4 mb-2">
					<ProgressBar percent={candidate?.percentage} height="h-[10px]" />
				</div>
			</div>
		</div>
		<div className="bg-black/5 rounded-lg px-5 py-3 dir-rtl flex gap-3 items-center">
			<span className="text-sm text-slate-600 font-faseyha relative -bottom-[2px]">ވޯޓު</span>
			<span className="text-sm font-medium text-slate-600">{formatNumberWithCommas(candidate?.votes || 0)}</span>
			<span className="text-sm font-medium text-slate-600">{candidate?.percentage?.toFixed(2)}%</span>
		</div>
	</div>
    )
}

export default CandidateCard;